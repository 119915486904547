import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { useSiteMetadata } from '../hooks/useMetadata';
import { TabelOfContents } from '../components/tabelOfContents';
import { Seo } from '../components/seo';
import { Timeline } from '../components/timeline2';
import { filterEdge } from '../helpers/filterEdge';

import '../styles/page.css';

export default function PostPage({ data, location }) {
  let { pathname } = location;
  const slug = pathname.substring(1);
  const { frontmatter, body, excerpt, tableOfContents, next, prev } =
    filterEdge(data.content2.edges, slug);

  const { title } = frontmatter;
  const titleId = title.toLowerCase().replace(/\s/g, '');
  const [tocItems, setTocItems] = useState([
    { title: title, url: `#${titleId}` },
  ]);

  const { siteUrl } = useSiteMetadata();

  useEffect(() => {
    if (tableOfContents.items) {
      const newTocItems = [
        ...tableOfContents.items.slice(0, 0),
        {
          title: title,
          url: `#${titleId}`,
        },
        ...tableOfContents.items.slice(0),
      ];
      setTocItems(newTocItems);
    }
  }, []);

  return (
    <Layout next={next} prev={prev}>
      <Seo
        title={title}
        description={excerpt}
        pathname={`${siteUrl}/${slug}`}
        article
      />
      <div className={`main-grid ${!tocItems?.length ? 'single-column' : ''}`}>
        {tocItems && tocItems.length > 0 && (
          <section className="column-left">
            <TabelOfContents data={tocItems} />
          </section>
        )}
        <section className="column-main">
          <h1 className="mt-0" id={titleId}>
            {title}
          </h1>
          <Timeline data={data.timeline.nodes} />
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String, $slugTimeline: String) {
    content: mdx(slug: { eq: $slug }) {
      id
      body
      excerpt(pruneLength: 250)
      slug
      tableOfContents
      frontmatter {
        title
      }
    }
    timeline: allMdx(
      filter: {
        slug: { regex: $slugTimeline }
        frontmatter: { type: { eq: "timeline" } }
      }
    ) {
      nodes {
        body
        frontmatter {
          title
          date
          branch
        }
      }
    }
    content2: allMdx(
      filter: { frontmatter: { type: { ne: "timeline" } } }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        next {
          frontmatter {
            title
          }
          slug
        }
        node {
          id
          excerpt(pruneLength: 250)
          slug
          body
          tableOfContents
          frontmatter {
            title
          }
        }
        previous {
          frontmatter {
            title
          }
          slug
        }
      }
    }
  }
`;
