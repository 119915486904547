export function filterEdge(arr, slug) {
  const edge = arr.find((block) => block.node.slug === slug);
  const next = edge.next
    ? {
        slug: `/${edge.next.slug}`,
        name: edge.next.frontmatter.title,
      }
    : null;
  const prev = edge.previous
    ? {
        slug: `/${edge.previous.slug}`,
        name: edge.previous.frontmatter.title,
      }
    : null;

  return {
    next,
    prev,
    ...edge.node,
  };
}
