import React, { useEffect, useState } from 'react';
import useMedia from 'use-media';

export function TabelOfContents({ data }) {
  const isMobile = useMedia({ maxWidth: 767 });
  const [isOpen, setOpen] = useState(isMobile);

  const smoothScroll = (e, url) => {
    e.preventDefault();
    document.querySelector(url).scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = (e, url) => {
    if (isMobile) {
      setOpen(false);
    }
    smoothScroll(e, url);
  };

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobile]);

  return (
    data && (
      <nav className="table-of-contents" aria-label="Table of contents">
        {isMobile && (
          <div
            className="toc-heading flex flex-row justify-between items-center"
            role="button"
            onClick={() => setOpen(!isOpen)}
          >
            <h4 className="mb-0">Table of Contents</h4>
            {isOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
          </div>
        )}
        {isOpen && (
          <ul className="ml-4 mt-2 text-sm leading-5 text-gray-600 dark:text-gray-300">
            {data.map((heading) => (
              <li
                key={heading.url}
                className=" py-2 md:py-0  hover:text-gray-900   dark:hover:text-gray-50 transition-colors cursor-pointer"
              >
                <a
                  href={heading.url}
                  onClick={(e) => handleClick(e, heading.url)}
                  className="no-underline"
                >
                  {heading.title}
                </a>
                {heading.items && (
                  <ul className="ml-4 mt-2" style={{ listStyleType: 'circle' }}>
                    {heading.items.map((item) => (
                      <li key={item.url}>
                        <a
                          href={item.url}
                          onClick={(e) => smoothScroll(e, item.url)}
                          className="no-underline "
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </nav>
    )
  );
}
