import React from 'react';

export function Switch({ value, toggleSwitch }) {
  function handleSwitch({ target }) {
    toggleSwitch(!value);
    if (document.getElementById('timeline-switch').checked) {
      target.style.setProperty('--bg-btn', '#bae6fd');
      target.style.setProperty('--btn-color', '#075985');
    } else {
      target.style.setProperty('--bg-btn', '#a7f3d0');
      target.style.setProperty('--btn-color', '#065f46');
    }
  }

  return (
    <div className="flex justify-start gap-2 mb-4">
      <p className={`mb-0 ${value ? 'text-blue-800 dark:text-blue-300' : ''}`}>
        Web
      </p>
      <div className="btn-status">
        <input
          type="checkbox"
          name="timeline-switch"
          id="timeline-switch"
          className="hidden"
        />
        <label
          htmlFor="timeline-switch"
          className={`btn-change flex items-center p-1 rounded-xl w-12 h-6 cursor-pointer`}
          role="switch"
          aria-checked={value}
          aria-label="Web Game Switch"
          onClick={handleSwitch}
          tabIndex={0}
        />
      </div>
      <p
        className={`mb-0 ${!value ? 'text-green-800 dark:text-green-300' : ''}`}
      >
        Game
      </p>
    </div>
  );
}
