import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { useBranchTimeline } from '../hooks/useBranchTimeline';
import { Switch } from './switch';

export function Timeline({ data }) {
  const { isLeft, toggleBranch, branchTimeline, hasNoBranch } =
    useBranchTimeline(data);

  return (
    <section className="w-full mb-10">
      {hasNoBranch && <Switch value={isLeft} toggleSwitch={toggleBranch} />}
      <div className="timeline">
        {branchTimeline.map((block, index) => (
          <div
            key={block.frontmatter.title + index}
            className="grid grid-cols-1 mb-6"
          >
            <div className="w-full pr-4 md:py-6 text-blue-900 dark:text-blue-300 ">
              {block.frontmatter.date}
            </div>
            <div className="w-full py-4 pr-4">
              <h2>{block.frontmatter.title}</h2>
              <MDXRenderer>{block.body}</MDXRenderer>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
