import { useEffect, useState } from 'react';

export function useBranchTimeline(data) {
  const [isLeft, toggleBranch] = useState(true);
  const [hasNoBranch, setNoBranch] = useState(false);

  const filterBranch = (arr, isLeft = true) =>
    arr.filter(
      (block) =>
        block.frontmatter.branch === (isLeft ? 'web' : 'game') ||
        block.frontmatter.branch === null
    );

  const [branchTimeline, setBranchTimeline] = useState(filterBranch(data));

  useEffect(() => {
    const branchTimeline = filterBranch(data, isLeft);
    setNoBranch(
      branchTimeline.some((block) => block.frontmatter.branch !== null)
    );
    setBranchTimeline(branchTimeline);
  }, [isLeft, data]);

  return {
    isLeft,
    toggleBranch,
    branchTimeline,
    hasNoBranch,
  };
}
